import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)


export default new Router({
	mode: 'history',
	base: '/',
	routes: [
		{
			path: '/yqjc',
			name: 'yqjc',
			component: () => import('@/components/txwk/yqjc.vue'),
			meta: {
				title: '',
				keepAlive: false
			}
		},
		{
			path: '/czfb',
			name: 'czfb',
			component: () => import('@/components/txwk/czfb.vue'),
			meta: {
				title: '',
				keepAlive: true
			}
		},
		{
			path: '/barcode',
			name: 'barcode',
			component: () => import('@/components/txwk/barcode.vue'),
			meta: {
				title: '',
				keepAlive: true
			}
		},
		{
			path: '/czfb-add',
			name: 'czfb-add',
			component: () => import('@/components/txwk/czfb-add.vue'),
			meta: {
				title: '',
				keepAlive: true
			}
		},
		{
			path: '/chat',
			name: 'chat',
			component: () => import('@/components/txwk/chat.vue'),
			meta: {
				title: '',
				keepAlive: false
			}
		},
		{
			path: '/ckfb-add',
			name: 'ckfb-add',
			component: () => import('@/components/txwk/ckfb-add.vue'),
			meta: {
				title: '',
				keepAlive: true
			}
		},
		{
			path: '/shengpi',
			name: 'shengpi',
			component: () => import('@/components/txwk/shengpi.vue'),
			meta: {
				title: '',
				keepAlive: false
			}
		},
		{
			path: '/fxry',
			name: 'fxry',
			component: () => import('@/components/txwk/fxry.vue'),
			meta: {
				title: '',
				keepAlive: false
			}
		},
		{
			path: '/zybrlb',
			name: 'zybrlb',
			component: () => import('@/components/txwk/zybrlb.vue'),
			meta: {
				title: '',
				keepAlive: false
			}
		},
		{
			path: '/phrylb',
			name: 'phrylb',
			component: () => import('@/components/txwk/phrylb.vue'),
			meta: {
				title: '',
				keepAlive: false
			}
		},
		{
			path: '/trip-info',
			name: 'trip-info',
			component: () => import('@/components/txwk/trip-info.vue'),
			meta: {
				title: '行程详情',
				keepAlive: false
			}
		},
		{
			path: '/auth-page',
			name: 'auth-page',
			component: () => import('@/components/txwk/auth-page.vue'),
			meta: {
				title: '行程详情',
				keepAlive: false
			}
		},
		{
			path: '/tjd-sel',
			name: 'tjd-sel',
			component: () => import('@/components/txwk/tjd-sel.vue'),
			meta: {
				title: '添加途经点',
				keepAlive: false
			}
		},
		{
			path: '/sjfb',
			name: 'sjfb',
			component: () => import('@/components/txwk/sjfb.vue'),
			meta: {
				title: '发布行程',
				keepAlive: false
			}
		},
		{
			path: '/ckfb',
			name: 'ckfb',
			component: () => import('@/components/txwk/ckfb.vue'),
			meta: {
				title: '发布行程',
				keepAlive: false
			}
		},
		{
			path: '/my',
			name: 'my',
			component: () => import('@/components/txwk/my.vue'),
			meta: {
				title: '我的',
				keepAlive: false
			}
		},
		{
			path: '/wsxx',
			name: 'wsxx',
			component: () => import('@/components/txwk/wsxx.vue'),
			meta: {
				title: '完善信息',
				keepAlive: false
			}
		},
		{
			path: '/wdfb',
			name: 'wdfb',
			component: () => import('@/components/txwk/wdfb.vue'),
			meta: {
				title: '我的发布',
				keepAlive: false
			}
		},
		{
			path: '/passenger-info',
			name: 'passenger-info',
			component: () => import('@/components/txwk/passenger-info.vue'),
			meta: {
				title: '我的同行',
				keepAlive: false
			}
		},
		//拼菜
		{
			path: '/sjfb-list',
			name: 'sjfb-list',
			component: () => import('@/components/shpc/sjfb-list.vue'),
			meta: {
				title: '商家发布',
				keepAlive: false
			}
		},
		{
			path: '/add-cp',
			name: 'add-cp',
			component: () => import('@/components/shpc/add-cp.vue'),
			meta: {
				title: '新增',
				keepAlive: false
			}
		},
		{
			path: '/gkyy-list',
			name: 'gkyy-list',
			component: () => import('@/components/shpc/gkyy-list.vue'),
			meta: {
				title: '新增',
				keepAlive: false
			}
		},
		{
			path: '/my-yy',
			name: 'my-yy',
			component: () => import('@/components/shpc/my-yy.vue'),
			meta: {
				title: '我的预约',
				keepAlive: false
			}
		},
		{
			path: '/sjckyy',
			name: 'sjckyy',
			component: () => import('@/components/shpc/sjckyy.vue'),
			meta: {
				title: '今日预约',
				keepAlive: false
			}
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('@/components/login/login.vue'),
			meta: {
				title: '登录',
				keepAlive: false
			}
		},
		{
			path: '/mclogin',
			name: 'mclogin',
			component: () => import('@/components/login/mclogin.vue'),
			meta: {
				title: '登录',
				keepAlive: false
			}
		},
		{
			path: '/czsh',
			name: 'czsh',
			component: () => import('@/components/txwk/czsh.vue'),
			meta: {
				title: '车主审核',
				keepAlive: false
			}
		},
		{
			path: '/posts-list',
			name: 'posts-list',
			component: () => import('@/components/posts/posts-list.vue'),
			meta: {
				title: '我的圈子',
				keepAlive: false
			}
		},
		{
			path: '/my-question-list',
			name: 'my-question-list',
			component: () => import('@/components/question/my-question-list.vue'),
			meta: {
				title: '我的投诉建议',
				keepAlive: false
			}
		},
		{
			path: '/add-question',
			name: 'add-question',
			component: () => import('@/components/question/add-question.vue'),
			meta: {
				title: '填写投诉建议',
				keepAlive: false
			}
		},
		{
			path: '/all-question-list',
			name: 'all-question-list',
			component: () => import('@/components/question/all-question-list.vue'),
			meta: {
				title: '处理投诉建议',
				keepAlive: false
			}
		},
		{
			path: '/add-posts',
			name: 'add-posts',
			component: () => import('@/components/posts/add-posts.vue'),
			meta: {
				title: '发圈子',
				keepAlive: false
			}
		},
		{
			path: '/mzsm',
			name: 'mzsm',
			component: () => import('@/components/txwk/mzsm.vue'),
			meta: {
				title: '发圈子',
				keepAlive: false
			}
		},
		{
			path: '/goods-list',
			name: 'goods-list',
			component: () => import('@/components/goods/goods-list.vue'),
			meta: {
				title: '闲置',
				keepAlive: false
			}
		},
		{
			path: '/goods-add',
			name: 'goods-add',
			component: () => import('@/components/goods/goods-add.vue'),
			meta: {
				title: '发闲置',
				keepAlive: false
			}
		},
		{
			path: '/goods-detail',
			name: 'goods-detail',
			component: () => import('@/components/goods/goods-detail.vue'),
			meta: {
				title: '发闲置',
				keepAlive: false
			}
		},
		{
			path: '/goods-chat',
			name: 'goods-chat',
			component: () => import('@/components/goods/goods-chat.vue'),
			meta: {
				title: '发闲置',
				keepAlive: false
			}
		},
		{
			path: '/my-goods-list',
			name: 'my-goods-list',
			component: () => import('@/components/goods/my-goods-list.vue'),
			meta: {
				title: '我的发布',
				keepAlive: false
			}
		},
		{
			path: '/goods-order',
			name: 'goods-order',
			component: () => import('@/components/goods/goods-order.vue'),
			meta: {
				title: '我的发布',
				keepAlive: false
			}
		},
	]
})