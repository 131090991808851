<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>
<script>
window._AMapSecurityConfig = {
  securityJsCode: "ec240341b230eba63bda256fce4d76c4",
};
import Watermark from '@/utils/watermark.js'
export default {
    mounted: function () {
        if (navigator.userAgent.indexOf('iPhone') !== -1) {
            // IOS 记录微信菜单打开时的url
            window.entryUrl = location.href.split('#')[0]
        }
        Watermark.set("生活圈")
    }
}

</script>