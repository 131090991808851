import Vue from "vue";
// 解决低版本兼容性问题
import 'babel-polyfill';
import Es6Promise from 'es6-promise';
require('es6-promise').polyfill();
Es6Promise.polyfill();
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CONFIG from "./config";
// 过滤器
import "./utils/filters";
// 第三方库
import loadsh from "loadsh";
import Storage from "vue-ls";
// import Antd from "ant-design-vue";
import Vant from "vant";
import animated from 'animate.css'
import dayjs from 'dayjs'
import zhCn from 'dayjs/locale/zh-cn'
dayjs.locale(zhCn)
// app.config.globalProperties.$dayjs = dayjs
Vue.prototype.dayjs = dayjs;
import "vant/lib/index.css";
// import "ant-design-vue/dist/antd.less";
Vue.use(Storage, CONFIG.storageOptions);
Vue.config.productionTip = false;
Vue.prototype._ = loadsh;
// Vue.use(Antd);
Vue.use(Vant);
Vue.use(animated)
// https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf1e471795a1fd4e4&redirect_uri=https://ccb.yayale.top/login?resouce=my&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect

new Vue({
    render: (h) => h(App),
    router,
    store,
}).$mount("#app");
