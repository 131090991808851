export default {
	// 缩放比例 最小兼容1280px宽度屏幕
	scale: 0.0067,
	// api接口
	api: {
		// tangzf: 'http://zzj.shulan.com',
		tangzf: '',
		meizl: '',
		meizldp: `/ydyf`,
		api: ''
	},
	pathUrl: {
		basePathUrl: process.env.VUE_APP_FLAG == 'prod' ? 'https://chqsq.shulanhealth.com' :  'https://testdpapi.shulanhealth.com'
	},
	// cdn资源
	resource: {
		base: 'http://public.yayale.top/', // cdn资源地址
	},
	// vue-ls options
	storageOptions: {
		namespace: 'slsz__', // key prefix
		name: 'ls', // name variable Vue.[ls] or this.[$ls],
		storage: 'local', // storage name session, local, memory
	}
}